import React, { useContext, useEffect } from 'react';
import { Avatar, Badge, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Tab, Tabs } from '@engyalo/design-system';
import { UserStatus } from 'types/user';
import { managementRoles } from 'constants/roles';
import Sessions from './YourChats';
import TabPanel from '../../../components/Tabs/TabPanel';
import { Container, TabsContainer } from './style';

import MenuFilterDepartments from './MenuFilterDepartments';
import useQueueMenu from './useQueueMenu';
import AllChats from './AllChats';
import { ModalChangeStatus } from './ModalChangeStatus';
import { ModalLogout } from './ModalLogout';
import ErrorModal from '../../../components/ErrorModal';

import MenuOptions from '../../../components/MenuOption';
import MenuChatsContext from '../../../contexts/menuChats/context';
import AuthContext from '../../../contexts/auth/context';
import usePermissions from '../../../hooks/usePermissions';
import { useFeatureFlag, flag } from '../../../hooks/useFeatureFlag';
import { ModalPreferences } from './ModalPreferences';
import EditProfile from '../EditProfile';
import ModalWarningMobile from './ModalWarningMobile';
import useSegment from '../../../services/Analytics';
import { logError } from '../../../services/Logger';
import { STORAGE_KEYS } from '../../../constants/defaultValues';
import PreferencesProvider from '../../../contexts/preferences/provider';

const QueueMenu: React.FC = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const { accordeonsYourChats } = useContext(MenuChatsContext);
  const { currentUserInfo } = useContext(AuthContext);

  const { checkPermission } = usePermissions();
  const navigate = useNavigate();
  const hasStrictManagementRoles = useFeatureFlag(flag.STRICT_MANAGEMENT_ROLES);

  const {
    departments,
    tabTitle,
    showModalChangeStatus,
    showModalLogout,
    showEditProfileModal,
    showErrorModal,
    setShowModalChangeStatus,
    setShowModalLogout,
    setShowErrorModal,
    errorMessageKey,
    changeStatus,
    handleChange,
    handleOptionClick,
    handleClickAvatar,
    handleClose,
    tabValue,
    anchorEl,
    anchorElAvatar,
    statusOptions,
    domain,
    setStatusOptions,
    handleClickPreferences,
    openPreferences,
    setShowEditProfileModal,
    handleOpenAdminPanel,
    isMobile,
    showModalIsMobile,
    setShowModalIsMobile,
    avatarUrl,
    setAvatarUrl,
  } = useQueueMenu();
  const { sendEvent, EVENT_NAMES } = useSegment();
  const languageLocalStorage = localStorage.getItem(STORAGE_KEYS.LANGUAGE);
  const shouldShowAllChats = !hasStrictManagementRoles || checkPermission(managementRoles);

  useEffect(() => {
    if (currentUserInfo && currentUserInfo._id) {
      const options = [
        {
          id: 'StatusOn',
          icon: <i className="fa-solid fa-circle" style={{ color: theme.palette.success.main }} />,
          title: t('online'),
          function: async () => {
            try {
              changeStatus('online');
              sendEvent(EVENT_NAMES.USER_CHANGE_STATUS_ONLINE);
            } catch (e) {
              logError('Error while changing status', e);
            }
          },
        },
        {
          id: 'StatusOff',
          icon: <i className="fa-regular fa-circle" style={{ color: theme.palette.neutral.var50 }} />,
          title: t('offline'),
          function: async () => {
            if (accordeonsYourChats[0].rooms.length === 0) {
              try {
                changeStatus('offline');
                sendEvent(EVENT_NAMES.USER_CHANGE_STATUS_OFFLINE);
              } catch (e) {
                logError('Error while changing status', e);
              }
            } else {
              setShowModalChangeStatus(true);
            }
          },
        },
        {
          component: <Divider key="divider-key2" sx={{ margin: '4px 12px' }} />,
        },
      ] as Array<any>;

      if (checkPermission(managementRoles)) {
        options.push({
          id: 'adminPanel',
          icon: <i className="fa-regular fa-gear" />,
          title: t('adminPanel'),
          function: () => {
            handleOpenAdminPanel();
          },
        });
      }
      options.push(
        {
          id: 'preferences',
          icon: <i className="fa-regular fa-sliders" />,
          title: t('preferences'),
          function: () => {
            handleClickPreferences();
          },
        },
        {
          id: 'edit-profile',
          icon: <i className="fa-regular fa-circle-user" />,
          title: t('editProfile'),
          function: () => {
            setShowEditProfileModal(true);
          },
        },
        {
          id: 'exit',
          icon: (
            <i className="fa-light fa-arrow-right-from-bracket fa-sm" style={{ color: theme.palette.neutral.var80 }} />
          ),
          title: t('exit'),
          function: () => setShowModalLogout(true),
        }
      );
      setStatusOptions(options);
    }
  }, [currentUserInfo._id, currentUserInfo.statusLivechat, domain, isMobile, languageLocalStorage]);

  useEffect(() => {
    setStatusOptions((oldState) =>
      oldState.map((item) => {
        switch (item.id) {
          case 'StatusOn':
            item.title = t('online');
            break;
          case 'StatusOff':
            item.title = t('offline');
            break;
          case 'settings':
            item.title = t('settings');
            break;
          case 'managementChats':
            item.title = t('managementChats');
            break;
          case 'exit':
            item.title = t('exit');
            break;
        }

        return item;
      })
    );
  }, [i18n.language]);

  useEffect(() => {
    if (currentUserInfo?.avatarUrl?.includes('etag')) {
      setAvatarUrl(currentUserInfo?.avatarUrl);
    }
  }, [currentUserInfo.avatarUrl]);

  return (
    <Container data-testid="queueMenu">
      <TabsContainer style={{ gap: shouldShowAllChats ? undefined : '5rem' }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="tags tabs"
          variant={shouldShowAllChats ? undefined : 'fullWidth'}
          sx={{ width: shouldShowAllChats ? undefined : '100%' }}
        >
          <Tab
            label={t('yourChats')}
            key={0}
            style={{
              marginBottom: -8,
            }}
            id="my-conversations-tab-0"
            aria-controls="tags-tabpanel-0"
          />

          {shouldShowAllChats && (
            <Tab
              style={{
                alignItems: 'end',
              }}
              label={t(tabTitle)}
              id="all-conversations-tab-1"
              aria-controls="tags-tabpanel-1"
              key={1}
              iconPosition="end"
              icon={
                <div
                  onClick={(event: any) => {
                    event.preventDefault();
                    handleOptionClick(event.currentTarget.offsetParent);
                  }}
                >
                  <i className={`fa-regular ${anchorEl ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                </div>
              }
            />
          )}
        </Tabs>
        <Badge
          overlap="circular"
          badgeContent={
            currentUserInfo.status === UserStatus.Online ? (
              <i className="fa-solid fa-circle" style={{ color: theme.palette.success.main }} />
            ) : (
              <i className="fa-regular fa-circle" style={{ color: theme.palette.neutral.var50 }} />
            )
          }
          sx={{
            '.MuiBadge-badge': {
              top: '28px',
              right: '0px',
            },
          }}
        >
          <Avatar
            data-testid="btnHandleAvatar"
            aria-label={t('userAvatar')}
            src={
              currentUserInfo && currentUserInfo.username && avatarUrl !== '' ? avatarUrl : currentUserInfo.avatarUrl
            }
            onClick={(event: any) => handleClickAvatar(event)}
            sx={{ width: '36px', height: '36px', cursor: 'pointer' }}
          />
        </Badge>
      </TabsContainer>

      <TabPanel style={{ height: 'calc(100% - 4.8rem)' }} value={tabValue} index={0}>
        <Sessions />
      </TabPanel>

      <TabPanel style={{ height: 'calc(100% - 4.8rem)' }} value={tabValue} index={1}>
        <AllChats />
      </TabPanel>

      {tabValue === 1 && (
        <MenuFilterDepartments
          departments={departments}
          anchorEl={anchorEl}
          handleClose={handleClose}
          isMobile={isMobile}
        />
      )}

      {anchorElAvatar && (
        <MenuOptions
          options={statusOptions}
          anchorEl={anchorElAvatar}
          handleClose={handleClose}
          position="top"
          status={currentUserInfo.statusLivechat}
        />
      )}

      {showModalChangeStatus && (
        <ModalChangeStatus
          showModalChangeStatus={showModalChangeStatus}
          setShowModalChangeStatus={setShowModalChangeStatus}
          changeStatus={changeStatus}
        />
      )}

      {showModalLogout && <ModalLogout showModalLogout={showModalLogout} setshowModalLogout={setShowModalLogout} />}

      <ErrorModal
        open={showErrorModal}
        title={t('errorChangingLivechatStatus')}
        message={t(errorMessageKey, t('unexpectedError'))}
        handleClose={() => setShowErrorModal(false)}
      />

      {openPreferences && (
        <PreferencesProvider>
          <ModalPreferences open={openPreferences} handleClose={handleClickPreferences} />
        </PreferencesProvider>
      )}

      {showEditProfileModal && (
        <EditProfile handleCloseModal={() => setShowEditProfileModal(false)} open={showEditProfileModal} />
      )}

      {showModalIsMobile && (
        <ModalWarningMobile
          handleClose={(confirm) => {
            setShowModalIsMobile(false);
            if (confirm) {
              navigate('/service-manager');
            }
          }}
          open={showModalIsMobile}
        />
      )}
    </Container>
  );
};

export default QueueMenu;
