import { Box, Stack, Typography, Icon, useMediaQuery, Theme } from '@mui/material';
import React, { useContext, useState } from 'react';
import { CalendarPickerView, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DrawerMessageType } from 'components/DrawerMessages/types.d';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, TextField, Tab, Tabs } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import { SelectView } from 'components/SelectView/SelectView';
import InputSearch from '../../../components/InputSearch';
import Drawer from '../../../components/Drawer';
import DrawerMessages from '../../../components/DrawerMessages';
import CustomPagination from '../../../components/Pagination';
import CustomTable from '../../../components/Table';
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirstLetter';
import MenuChatsContext from '../../../contexts/menuChats/context';
import ChatManagerContext, {
  IChatTableItem,
  SortChatsManagerHome,
  DATE_FILTER_OPTIONS,
} from '../../../contexts/chatManager/context';
import {
  Header,
  Main,
  Pagination,
  StackTitle,
  TableContainer,
  Container,
  SearchContainer,
  CancelSelection,
  FiltersContainer,
  StackInputsAndButton,
  TabsContainer,
  FiltersGroup,
  DateRangeContainer,
  InputContainer,
} from './style';
import { ReactComponent as Loading } from '../../../assets/icons/loadingYalo.svg';
import ModalError from '../../../components/ErrorModal';
import { CustomChip } from '../../../components/Tags/style';
import { checkLanguage } from '../../../helpers/checkLanguage';
import AdvancedFilters from '../../../components/ChatsAdvancedFilters';
import TabPanel from '../../../components/Tabs/TabPanel';
import { SortDirection } from '../../../constants';
import { Roles } from '../../../constants/roles';
import usePermissions from '../../../hooks/usePermissions';
import { flag, useFeatureFlag } from '../../../hooks/useFeatureFlag';
import ConversationExplorer, { IConversationExplorerProps } from '../../ServiceManagement/ConversationExplorer';

const CANCEL_START_DATE = 'startDate';
const CANCEL_END_DATE = 'endDate';

export enum ChatManagerTabs {
  AGENTS = 0,
  CONVERSATION_EXPLORER = 1,
}

interface IChatsManagerProps {
  initialTab?: ChatManagerTabs;
  conversationExplorerParams?: IConversationExplorerProps;
}

const ChatsManager = ({ initialTab = ChatManagerTabs.AGENTS }: IChatsManagerProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    currentPage,
    tableRows,
    totalItems,
    handleCurrentPage,
    handleClickItem,
    showDrawer,
    handleShowDrawer,
    chatId,
    setContactName,
    setTicket,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    contactName,
    ticket,
    language,
    showError,
    handleShowError,
    showAdvancedFilters,
    handleCloseAdvancedFilter,
    handleClickAvancedFilter,
    anchorEl,
    handleFilterChats,
    currentAvancedFilters,
    agents,
    departmentsState,
    removeFilter,
    clearFilters,
    loading,
    loadChats,
    orderChat,
    orderByChat,
    filterDateBy,
    setFilterDateBy,
    lastUpdate,
  } = useContext(ChatManagerContext);

  const isMobile = useMediaQuery((mTheme: Theme) => mTheme.breakpoints.down('md'));
  const { checkPermission } = usePermissions();
  const hasStrictManagementRoles = useFeatureFlag(flag.STRICT_MANAGEMENT_ROLES);

  const refreshIcon = <Icon className="fa-solid fa-arrows-rotate" />;

  const [calendarView, setCalendarView] = useState('day');
  const [showLabelStartDate, setShowLabelStartDate] = useState(true);
  const [showLabelEndDate, setShowLabelEndDate] = useState(true);

  const [tabSelected, setTabSelected] = useState<ChatManagerTabs>(initialTab);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
  };

  const getChipFilter = (label: string, index: string) => (
    <CustomChip
      label={label}
      customcolor={{
        color: theme.palette.neutral.var20,
        text: theme.palette.neutral.var90,
      }}
      key={index}
      onDelete={() => removeFilter(index)}
      deleteIcon={
        <Icon
          className="fa-regular fa-xmark fa-2xs"
          sx={{
            color: 'neutral.var50',
            cursor: 'pointer',
          }}
        />
      }
    />
  );

  const showSelectFilters = () =>
    Object.entries(currentAvancedFilters).map(([key, value]) => {
      let label = '';

      if (key === 'tags') {
        const tags = value as string[];
        label = `${t('chatTags')}: ${tags.join(',')}`;
      } else if (key === 'departmentId') {
        label = departmentsState.find((item) => item._id === value)?.name || '';
      } else if (key === 'agentId') {
        label = agents.find((item) => item._id === value)?.name || '';
      } else if (key === 'customFields') {
        return Object.entries(value).map(([customKey, customValue]) => {
          label = `${customKey}: ${customValue}`;
          return getChipFilter(label, customKey);
        });
      } else {
        label = `${key}: ${value}`;
      }

      return getChipFilter(label, key);
    });

  const { setOpenChatsManager } = useContext(MenuChatsContext);

  const cancelSelect = (cancelSelection: string) => (
    <Stack
      sx={{
        position: 'absolute',
        bottom: 0,
        cursor: 'pointer',
        zIndex: 1,
        display: calendarView === 'day' ? 'flex' : 'none',
      }}
      width="100%"
    >
      <CancelSelection
        variant="body1"
        color="neutral.var80"
        onClick={() => (cancelSelection === 'startDate' ? setStartDate(null) : setEndDate(null))}
      >
        {t('cancelSelect')}
      </CancelSelection>
    </Stack>
  );

  const headerColumns: Array<keyof IChatTableItem> = [
    'ticket',
    'contact',
    'team',
    'attendedBy',
    'startedAt',
    'lastMessage',
    'closedAt',
  ];
  const handleRenderTableChatsManagerHome = () => {
    if (totalItems >= 1) {
      return (
        <>
          <TableContainer>
            <CustomTable
              handleClickItem={(item) => handleClickItem(item)}
              page={currentPage}
              headItems={headerColumns}
              rows={tableRows}
              hasButtonOptions={false}
              handleCallApi={(sortType: string, sortValue: number) => {
                loadChats(undefined, SortChatsManagerHome[sortType as keyof typeof SortChatsManagerHome], sortValue);
              }}
              order={orderChat}
              orderBy={orderByChat}
              tableName="chatsManager"
            />
          </TableContainer>
          <Pagination isMobile={isMobile}>
            <CustomPagination
              isMobile={isMobile}
              count={totalItems}
              page={currentPage}
              handleChangePage={handleCurrentPage}
            />
          </Pagination>
        </>
      );
    }

    if ((contactName || ticket || startDate || endDate) && !totalItems) {
      return (
        <Typography variant="subtitle1" color="neutral.var70" textAlign="center">
          {t('notFoundChats')}
        </Typography>
      );
    }

    if (!totalItems && !loading) {
      return (
        <Typography variant="subtitle1" color="neutral.var70" textAlign="center">
          {t('emptyChatsMessage')}
        </Typography>
      );
    }

    return (
      <Box alignSelf="center">
        <Loading />
      </Box>
    );
  };

  return (
    <Container data-testid="chatsManagerContainer">
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={checkLanguage(language)}>
        <Header isMobile={isMobile}>
          {isMobile && (
            <IconButton
              data-testid="closeContactManagerBtnMobile"
              onClick={() => setOpenChatsManager(false)}
              aria-label={t('close')}
              variant="text"
              color="neutral2"
            >
              <i className="fa-solid fa-arrow-left fa-2xs" />
            </IconButton>
          )}

          <StackTitle variant="subtitle2" color="neutral.var90">
            {capitalizeFirstLetter(t('conversationArchive'))}
          </StackTitle>

          {!isMobile && (
            <IconButton
              data-testid="closeContactManagerBtn"
              onClick={() => setOpenChatsManager(false)}
              variant="text"
              aria-label={t('close')}
              color="neutral2"
            >
              <i className="fa-regular fa-xmark" />
            </IconButton>
          )}
        </Header>

        <TabsContainer>
          <Tabs value={tabSelected} onChange={handleChangeTab} aria-label="contactManagerTabs">
            <Tab
              label={t('handledByAgents')}
              key={0}
              style={{ marginBottom: -8 }}
              id="section-tab-0"
              aria-controls="section-tabpanel-0"
            />

            <Tab
              style={{
                alignItems: 'end',
                display: hasStrictManagementRoles && !checkPermission(Roles.MANAGER) ? 'none' : 'block',
              }}
              label={t('flowOnly')}
              id="section-tab-1"
              aria-controls="section-tabpanel-1"
              key={1}
              iconPosition="end"
            />
          </Tabs>
        </TabsContainer>

        <TabPanel style={{ height: 'calc(100% - 4.8rem)' }} value={tabSelected} index={0}>
          <Main isMobile={isMobile}>
            {!isMobile ? (
              <Typography variant="h2" color="common.black">
                {capitalizeFirstLetter(t('conversations'))}
              </Typography>
            ) : null}
            <SearchContainer>
              <StackInputsAndButton>
                <InputContainer>
                  <InputSearch
                    testId="searchByTicket"
                    placeholder={t('searchByTicket')}
                    disableUnderline
                    value={ticket}
                    padding="0"
                    onChange={(value) => {
                      setTicket(value);
                    }}
                    debounce={500}
                  />
                </InputContainer>

                <InputContainer>
                  <InputSearch
                    testId="searchByContact"
                    placeholder={t('searchByContacts')}
                    disableUnderline
                    padding="0"
                    value={contactName}
                    onChange={(value) => {
                      setContactName(value);
                    }}
                    debounce={500}
                  />
                </InputContainer>

                <InputContainer>
                  <SelectView
                    testId="filterDateBy"
                    translation={t}
                    placeholder={t('filterDateBy')}
                    value={filterDateBy}
                    setValue={(value: string) => {
                      setFilterDateBy(value);
                    }}
                    onChange={(value: string) => {
                      setFilterDateBy(value);
                    }}
                    optionsAux={DATE_FILTER_OPTIONS}
                  />
                </InputContainer>

                <DateRangeContainer>
                  <DesktopDatePicker
                    disabled={filterDateBy === ''}
                    showToolbar
                    disableFuture
                    label={showLabelStartDate ? t('startDatePlaceholder') : null}
                    inputFormat="dd/MM/yyyy"
                    value={startDate}
                    onChange={setStartDate}
                    renderInput={(params) => (
                      <TextField {...params} onFocus={() => setShowLabelStartDate(false)} insidelabel />
                    )}
                    ToolbarComponent={() => cancelSelect(CANCEL_START_DATE)}
                    onViewChange={(view: CalendarPickerView) => setCalendarView(view)}
                    onClose={() => setCalendarView('day')}
                  />

                  <DesktopDatePicker
                    disabled={filterDateBy === '' || !startDate}
                    onError={() => {
                      handleShowError();
                      setEndDate(null);
                    }}
                    minDate={new Date(startDate ?? '2000-01-01')}
                    showToolbar
                    disableFuture
                    label={showLabelEndDate ? t('endDatePlaceholder') : null}
                    inputFormat="dd/MM/yyyy"
                    value={endDate}
                    onChange={setEndDate}
                    renderInput={(params) => (
                      <TextField {...params} onFocus={() => setShowLabelEndDate(false)} insidelabel />
                    )}
                    ToolbarComponent={() => cancelSelect(CANCEL_END_DATE)}
                    onViewChange={(view: CalendarPickerView) => setCalendarView(view)}
                    onClose={() => setCalendarView('day')}
                  />
                </DateRangeContainer>
              </StackInputsAndButton>
              <Button
                size="small"
                onClick={(event) => handleClickAvancedFilter(event)}
                style={{
                  width: isMobile ? '100%' : '',
                  marginBottom: isMobile ? '15px' : '0',
                  minInlineSize: 'fit-content',
                }}
                variant="outlined"
              >
                {t('advancedFilters')}
              </Button>
            </SearchContainer>
            {Object.keys(currentAvancedFilters).length ? (
              <FiltersContainer>
                <i
                  role="presentation"
                  className="fa-regular fa-filter-slash"
                  style={{ cursor: 'pointer' }}
                  onClick={() => clearFilters()}
                />
                {showSelectFilters()}
              </FiltersContainer>
            ) : null}
            <FiltersGroup>
              <Typography variant="caption1" color="neutral.var60" align="right" noWrap>
                <b>{t('updatedAt')}</b> <br />
                {lastUpdate}
              </Typography>
              <Button
                color="neutral"
                size="small"
                endIcon={refreshIcon}
                onClick={() =>
                  loadChats(
                    undefined,
                    orderByChat,
                    orderChat === 'desc' ? SortDirection.DESC : SortDirection.ASC,
                    undefined,
                    false
                  )
                }
              >
                {t('update')}
              </Button>
            </FiltersGroup>
            {handleRenderTableChatsManagerHome()}
          </Main>
        </TabPanel>

        <TabPanel style={{ height: 'calc(100% - 4.8rem)' }} value={tabSelected} index={1}>
          <ConversationExplorer />
        </TabPanel>

        {showDrawer ? (
          <Drawer open={showDrawer}>
            <Stack
              sx={{
                width: '100%',
                height: '100%',
                backgroundColor: 'common.white',
              }}
            >
              <DrawerMessages
                roomId={chatId}
                type={DrawerMessageType.HISTORY}
                user="admin"
                // The flow of handling the drawer and the use of context should be reviewed.
                onClose={handleShowDrawer}
              />
            </Stack>
          </Drawer>
        ) : null}

        {showError ? (
          <ModalError
            title={t('invalidDate')}
            message={t('invalidDateMessage')}
            open={showError}
            handleClose={handleShowError}
          />
        ) : null}

        {showAdvancedFilters ? (
          <AdvancedFilters
            handleClose={handleCloseAdvancedFilter}
            anchorEl={anchorEl}
            agents={agents}
            departments={departmentsState}
            handleValue={(filters) => handleFilterChats(filters)}
            removeFilterByChatStatus
            currentFilters={currentAvancedFilters}
          />
        ) : null}
      </LocalizationProvider>
    </Container>
  );
};

export default ChatsManager;
