enum FeatureFlags {
  CHAT_COPILOT_ASSISTANT = 'chat-copilot-assistant',
  BUSINESS_HOURS = 'salesdesk-business-hours',
  DELIVERED_STATUS = 'salesdesk-delivered-message-status',
  READ_STATUS = 'salesdesk-read-message-status',
  USER_CONSENT_SCRIPT = 'enable-user-script-salesdesk',
  LIMIT_CHATS_IN_ACCORDION = 'salesdesk-limit-chats-in-accordion',
  SALESDESK_CHAT_MENU_WEBSOCKET = 'salesdesk-chat-menu-websocket',
  FIFO_INQUIRIES = 'salesdesk-fifo-inquiries',
  SHOW_ON_HOLD_METRIC = 'salesdesk-show-on-hold',
  SHOW_WAITING_FOR_SERVICE_LABEL = 'salesdesk-chats-waiting-for-service',
  ALLOW_SETTING_MAX_CHATS = 'salesdesk-allow-setting-max-chats',
  SHOULD_GET_TEMPLATES_BY_DEPARTMENT = 'salesdesk-templates-by-department',
  STRICT_MANAGEMENT_ROLES = 'salesdesk-strict-management-roles',
}

export default FeatureFlags;
