import React, { useContext, useEffect, useState, useMemo } from 'react';
import { logError } from 'services/Logger';
import usePermissions from 'hooks/usePermissions';
import { getUnits } from 'services/getUnits';
import { managementRoles, Roles } from 'constants/roles';
import { getListDeparmentsUnit } from 'helpers/getByUnits';
import { getDepartmentsInfo } from '../../services/getDepartmentsInfo';
import AuthContext from '../auth/context';
import DepartmentContext from './context';
import { IDepartment } from '../../types/department';
import { useFeatureFlag, flag } from '../../hooks/useFeatureFlag';

const DepartmentProvider: React.FC = (props) => {
  const { children } = props;
  const { currentUserInfo } = useContext(AuthContext);
  const { checkPermission } = usePermissions();
  const hasStrictManagementRoles = useFeatureFlag(flag.STRICT_MANAGEMENT_ROLES);

  const [departmentsState, setDepartments] = useState<IDepartment[]>([]);

  const loadDepartments = async () => {
    const userHasDepartments =
      currentUserInfo._id && currentUserInfo.departments && currentUserInfo.departments.length > 0;

    const shouldListAllDepartments = hasStrictManagementRoles
      ? checkPermission(Roles.MANAGER)
      : checkPermission(managementRoles);

    if (shouldListAllDepartments) {
      try {
        const {
          data: { units },
        } = await getUnits();
        const departmentsPromises = units.map((unit) => getListDeparmentsUnit(unit._id));
        const departments = (await Promise.all(departmentsPromises)).flat().filter((item) => item.enabled);
        setDepartments(departments);
      } catch (error) {
        logError('Error while fetching departments info', error);
      }
    } else if (userHasDepartments) {
      try {
        const departmentsIds = currentUserInfo.departments.map((item) => item.departmentId);
        const {
          data: { departments },
        } = await getDepartmentsInfo(departmentsIds);
        setDepartments(departments);
      } catch (error) {
        logError('Error while fetching departments info', error);
      }
    }
  };

  useEffect(() => {
    loadDepartments();
  }, [currentUserInfo.departments]);

  const memoizedValues = useMemo(
    () => ({
      departments: departmentsState,
      currentUserInfo,
    }),
    [departmentsState, currentUserInfo]
  );

  return <DepartmentContext.Provider value={memoizedValues}>{children}</DepartmentContext.Provider>;
};

export default DepartmentProvider;
